import React from "react";
import AdSense from "react-adsense-ads";

const PUBLISHER_ID = "ca-pub-9775313232513931"

// ads with no set-up
const NormalAds = () => {
  return <AdSense.Google client={PUBLISHER_ID} slot="Ad slot Id" />;
};

// ads with custom format
const BannerAds = () => {
  return (
    <AdSense.Google
      client={PUBLISHER_ID}
      slot="Ad slot Id"
      style={{ width: 500, height: 300, float: "left" }}
      format=""
    />
  );
};

// responsive and native ads
const ResponsiveNativeAds = () => {
  return (
    <AdSense.Google
      client={PUBLISHER_ID}
      slot="6803298649"
      style={{ display: "block" }}
      layout="in-article"
      format="fluid"
    />
  );
};

// auto full width responsive ads
const AutoFullWidthAds = () => {
  return (
    <AdSense.Google
      client={PUBLISHER_ID}
      slot="7924808622"
      style={{ display: "block" }}
      format="auto"
      responsive="true"
    />
  );
};

const InfeedAds = () => {
    return (
      <AdSense.Google
        client={PUBLISHER_ID}
        slot="7924808622"
        style={{ display: "block" }}
        format="auto"
        responsive="true"
      />
    );
  };

  const MultiplexAd = () => {
    return (
      <AdSense.Google
        client={PUBLISHER_ID}
        slot="5775225800"
        style={{ display: "block" }}
        format="autorelaxed"
        responsive="true"
      />
    );
  };

export{
    NormalAds,
    BannerAds,
    AutoFullWidthAds,
    ResponsiveNativeAds,
    InfeedAds,
    MultiplexAd
}