import { Typography, Box, Grid } from "@mui/material";
import React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Download, PlayArrow } from "@mui/icons-material";
import { useState } from "react";

function StreamOrDownload({ no_of_seasons, fetchData }) {
    fetchData()
  let seasons = [];
  if (no_of_seasons) {
    for (let a = 0; a < no_of_seasons; a++) {
      seasons.push(a + 1);
    }
  }
  const [open, setOpen] = React.useState(false);
  const [links, setLinks] = useState(0)

  const handleClick = (index) => {
    console.log(index);
    setLinks(index)
    setOpen(!open);
  };

  return (
    <div>
      <List
        sx={{
          width: "100%",
          //   bgcolor: "background.paper",
          color: "white",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }} //, maxWidth: "inherit"
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <Typography variant="h4" color="white">
            STREAM OR DOWNLOAD
          </Typography>
          //   <ListSubheader component="div" id="nested-list-subheader">
          //     Nested List Items
          //   </ListSubheader>
        }
      >
        {no_of_seasons ? (
          seasons.map((season, index) => {
            return (
              <div key={index}>
                <ListItemButton onClick={()=>{handleClick(index)}} >
                  <ListItemIcon>
                    <PlayArrow />
                  </ListItemIcon>
                  <ListItemText primary={`Season ${season}`} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                {index == links?
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Typography>Episode</Typography>
                    <Grid container>
                      <Grid item>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <PlayArrow />
                          </ListItemIcon>
                          <ListItemText primary="Stream" />
                        </ListItemButton>
                      </Grid>
                      <Grid item>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <Download />
                          </ListItemIcon>
                          <ListItemText primary="Download" />
                        </ListItemButton>
                      </Grid>
                    </Grid>
                  </List>
                </Collapse>:null}
              </div>
              //   <Typography variant="h3" color="white" key={key}>
              //     Season {season}
              //   </Typography>
            );
          })
        ) : (
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            <ListItemText primary="Download" />
            {/* {open ? <ExpandLess /> : <ExpandMore />} */}
          </ListItemButton>
        )}
      </List>
    </div>
  );
}

export default StreamOrDownload;
