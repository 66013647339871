import Heading from "../Header/Heading";
import "./MainNav.css";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import HomeIcon from "../../images/home-icon.svg";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import MovieIcon from "../../images/movie-icon.svg";
import TheatersIcon from "../../images/series-icon.svg";
import $ from "jquery";
import { AccountCircle } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { User } from "../../contexts/UserContext";

$(function () {
  $(document).on("scroll", function () {
    var $nav = $(".navbar");
    $nav.toggleClass("scrolled", $(this).scrollTop() > $nav.height());
  });
});

const MainNav = () => {
  const history = useHistory();
  const accountUrl = process.env.REACT_APP_AUTH_URL;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    if (userExists()) {
      // console.log("dashboard coming");
      history.push("/dashboard");
    } else {
      handleMenuClick(e);
    }
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuClick = (path) => {
    handleClose();
    window.open(`${accountUrl}` + `${path}`, "_self");
  };

  // User State funcs starts
  const { user } = useContext(User);

  function userExists() {
    if (user != null) {
      console.log("user exist");
      console.log(user, "kkk");
      return true;
    }
    console.log("user is null");
    return false;
  }
  console.log(user, "iii");

  return (
    <>
      <nav className="navbar navbar-expand navbar-light fixed-top">
        <Link className="navbar-brand" to="/">
          <Heading />
        </Link>

        <div className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active  nav__link">
              <Link className="nav-link" to="/">
                <img
                  src={HomeIcon}
                  style={{
                    fontSize: "17px",
                    marginBottom: "5px",
                    marginRight: "0px",
                  }}
                  alt=""
                />
                Home <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item  nav__link">
              <Link className="nav-link" to="/trending">
                <WhatshotIcon
                  style={{
                    fontSize: "17px",
                    marginBottom: "5px",

                    marginRight: "2px",
                  }}
                />
                TRENDING
              </Link>
            </li>
            <li className="nav-item  nav__link">
              <Link className="nav-link" to="/all-movies">
                <img
                  src={MovieIcon}
                  style={{
                    fontSize: "17px",
                    marginBottom: "2px",
                    marginRight: "1px",
                  }}
                  alt=""
                />
                Movies
              </Link>
            </li>
            <li className="nav-item nav__link">
              <Link className="nav-link" to="/all-series">
                <img
                  src={TheatersIcon}
                  style={{
                    fontSize: "17px",
                    marginBottom: "5px",
                    marginRight: "1px",
                  }}
                  alt=""
                />
                TvSeries
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link className="nav-link" to="/not">
                Search
              </Link>
            </li> */}
          </ul>

          <div className="all__right">
            <div className="btn-login">
              <AccountCircle
                id="menu-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ color: "skyblue", cursor: "pointer" }}
              />
              <Menu
                id="basic-menu"
                style={{ zIndex: "9999" }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "menu-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    menuClick("signup");
                  }}
                >
                  Signup
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    menuClick("login");
                  }}
                >
                  Login
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MainNav;
