import { Typography } from '@mui/material'
import React from 'react'
import { useContext } from 'react'
import { User } from '../../contexts/UserContext'

function DashBoard() {
    const user = useContext(User)
  return (
    <div>
        <Typography variant='h1'>Welcome {user.userId}</Typography>
    </div>
  )
}

export default DashBoard