import React, { createContext, useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const User = createContext({});

// CHECKING AND GETTING USER TOKEN FROM LOCAL STORAGE OR URL IF NOT FOUND THEN SET USER TO NULL
let token;

// FUNCTION TO PARSE JWT TOKENS
function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

function UserContext({ children }) {
  const [user, setUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const queryKeys = new URLSearchParams(window.location.search);
    if (JSON.parse(localStorage.getItem("lxcltk"))) {
      token = JSON.parse(localStorage.getItem("lxcltk"));
    } else if (queryKeys.get("token")) {
      token = queryKeys.get("token");
      localStorage.setItem("lxcltk", token);
    }
    if (token) {
      setUser(parseJwt(token));
      console.log(user);
    } else {
      setUser(null);
    }
  }, []);

  useEffect(() => {
    if (token) {
      setUser(parseJwt(token));
      console.log(user);
    } else {
      setUser(null);
    }
  }, [token]);

  return <User.Provider value={{ token, user }}>{children}</User.Provider>;
}

export { User, UserContext };
